import axios from 'axios';
import { auth } from './Auth/firebase-service';
import { Filter } from '../types';

const SchemaVersion = {
  V1: 1,
  V2: 2,
};

/* -------------------------------------------------------------------------- */
/*                                    User                                    */
/* -------------------------------------------------------------------------- */
const getProfile = async () => {
  const apiURL = `${process.env.REACT_APP_BACKEND_BASE_URL}/sdk/profile`;
  const response = await fetch(apiURL, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
  });

  const { data } = await response.json();
  // console.log('data: ' + data);

  return data;
};

/* -------------------------------------------------------------------------- */
/*                              Video Processing                              */
/* -------------------------------------------------------------------------- */
const getVideos = async () => {
  const apiURL = `${process.env.REACT_APP_BACKEND_BASE_URL}/sdk/videos`;
  const response = await fetch(apiURL, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
  });

  const { data } = await response.json();

  return data;
};

const sendEmailEnquiry = async (enquiryData: any) => {
  try {
    const apiURL = `${process.env.REACT_APP_BACKEND_BASE_URL}/contact/enquiry`;
    const response = await fetch(apiURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(enquiryData),
    });

    return response;
  } catch (error) {
    console.error('Network error during enquiry submission:', error);
    throw new Error('Network error: Unable to connect to the server');
  }
}

const getThumbnail = async (video: string) => {
  const apiURL = `${process.env.REACT_APP_BACKEND_BASE_URL}/sdk/thumbnail?video=${video}`;
  const response = await fetch(apiURL, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
  });

  const thumbnailBlob = await response.blob();
  // console.log('thumbnail: ' + data);

  return thumbnailBlob;
};

const getVideo = async (video: string) => {
  const apiURL = `${process.env.REACT_APP_BACKEND_BASE_URL}/sdk/video?video=${video}`;
  const response = await fetch(apiURL, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
  });

  const videoBlob = await response.blob();

  return videoBlob;
};

const getOverlay = async (video: string) => {
  const apiURL = `${process.env.REACT_APP_BACKEND_BASE_URL}/sdk/overlay?video=${video}`;
  const response = await fetch(apiURL, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
  });

  if (!response.ok) return null;

  const videoBlob = await response.blob();

  return videoBlob;
};


const getSDKData = async (video: string) => {
  try {
    const apiURL = `${process.env.REACT_APP_BACKEND_BASE_URL}/sdk/data?video=${video}`;
    const token = await auth.currentUser?.getIdToken();

    if (!token) throw new Error('User is not authenticated.');

    const response = await fetch(apiURL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error(
        `API request failed: ${response.status} ${response.statusText}`
      );
    }

    const jsonResponse = await response.json();

    if (!jsonResponse || typeof jsonResponse !== 'object') {
      throw new Error('Invalid JSON response from API.');
    }

    const rawData = jsonResponse.data;

    const data = {
      ...rawData,
      ...(rawData?.graph_status && {
        graph_stats: rawData.graph_status,
        graph_status: undefined,
      }),
    };

    if (data && 'sv' in data && data['sv'] == 2) {
      return convertSchema2(data);
    }

    return data;
  } catch (error) {
    console.error('Error fetching SDK data:', error);
    return null;
  }
};

// Notify the server that the upload is completed
const notifyServer = async (
  jobID: string,
  videoID: string,
  fileName: string,
  projectID: string
) => {
  try {
    let formData = new FormData();

    formData.append('jobID', jobID);
    formData.append('videoID', videoID);
    formData.append('fileName', fileName);
    formData.append('projectID', projectID);

    await fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/sdk/upload/notify`, {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    });
    //console.log('Notification sent after upload');
  } catch (err) {
    console.error('Failed to send notification:', err);
  }
};

const getUploadLink = async (file: File) => {
  const fileName = file.name; // Get the file name from the File object
  let formData = new FormData();

  formData.append('fileName', fileName); // Append the fileName to the form data

  // Call the server to get the signed URL and other details
  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_BASE_URL}/sdk/upload`,
    {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    }
  );

  if (!response.ok) {
    console.error('Failed to upload file');
    return;
  }

  const resp = await response.json();

  // Handle successful response (upload the file, etc.)
  // console.log('Signed URL:', resp.data.signedURL);
  // console.log('File Name:', resp.data.fileName);
  // console.log('Video ID:', resp.data.videoID);
  // console.log('Job ID:', resp.data.jobID);

  return {
    videoID: resp.data.videoID,
    jobID: resp.data.jobID,
    fileName: resp.data.fileName,
    signedURL: resp.data.signedURL,
  };
};

const uploadVideo = async (
  signedURL: string,
  file: File,
  progressCallback: (arg0: number) => void
) => {
  try {
    const response = await axios.put(signedURL, file, {
      headers: {
        'Content-Type': 'application/octet-stream',
      },
      onUploadProgress: (progressEvent) => {
        if (progressEvent.lengthComputable) {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total!
          );
          progressCallback(percentCompleted);
          //console.log(`Upload progress: ${percentCompleted}%`);
        } else {
          console.warn('Upload progress: unknown size');
        }
      },
    });

    // Return a success message along with the response data
    return {
      success: true,
      message: 'Upload completed successfully',
      data: response.data, // Include any data returned by the response
    };
  } catch (error: any) {
    console.error('Error uploading file:', error);

    // Return an error message
    return {
      success: false,
      message: 'Error uploading file: ' + error.message,
    };
  }
};

const getUploadProgress = async (
  jobID: string,
  videoID: string,
  progressCallback: (arg0: string, arg1: any) => void,
  onCloseCallback: (arg0: string, arg1: any) => void
) => {
  let count = 0;
  const connectWebSocket = async () => {
    let completed = false;

    //console.log("Getting upload progress websocket...");
    const socket = new WebSocket(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/sdk/progress?job=${jobID}&auth=${await auth.currentUser?.getIdToken()}`
    );

    socket.onmessage = (event) => {
      let data = JSON.parse(event.data);

      // set status as "FAILED" after attempting to reconnect 3x
      if (count == 4) {
        data = { ...data, status: 'FAILED' };
      }

      progressCallback(jobID, data);
      // console.log(data);

      if (data.complete) {
        completed = true;
        //console.log("Job completed. Closing WebSocket connection.");

        socket.close();
        onCloseCallback(videoID, data);
      }
    };

    socket.onerror = (error) => {
      console.error('WebSocket Error:', error);
      socket.close();
    };

    socket.onclose = (event) => {
      //console.log("WebSocket connection closed");

      // Check if the close was intentional or due to an issue
      if (!event.wasClean && !completed) {
        console.log('WebSocket closed unexpectedly. Reconnecting...');
        reconnect();
      }
    };
  };

  const reconnect = async () => {
    count += 1;

    // Add a delay before reconnecting to avoid rapid retries
    await new Promise((resolve) => setTimeout(resolve, 2000));
    await connectWebSocket();
  };

  // Start the initial connection
  await connectWebSocket();
};

const markVideoForDeletion = async (video: string) => {
  const apiURL = `${process.env.REACT_APP_BACKEND_BASE_URL}/sdk/delete?video=${video}`;
  const response = await fetch(apiURL, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
  });

  const data = await response.text();
  // console.log('data: ' + data);

  return data;
};

const unmarkVideoFromDeletion = async (video: string) => {
  const apiURL = `${process.env.REACT_APP_BACKEND_BASE_URL}/sdk/restore?video=${video}`;
  const response = await fetch(apiURL, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
  });

  const data = await response.text();
  // console.log('data: ' + data);

  return data;
};

const permanentlyDeleteVideo = async (video: string) => {
  const apiURL = `${process.env.REACT_APP_BACKEND_BASE_URL}/sdk/erase?video=${video}`;
  const response = await fetch(apiURL, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
  });

  const { data } = await response.json();
  // console.log('data: ' + data);

  return data;
};

/* -------------------------------------------------------------------------- */
/*                             Project Management                             */
/* -------------------------------------------------------------------------- */
const createProjectInBackend = async (project: string, description: string) => {
  const apiURL = `${process.env.REACT_APP_BACKEND_BASE_URL}/projects/create`;

  // Prepare the request body with project and description
  const requestBody = {
    project: project,
    description: description,
  };

  const response = await fetch(apiURL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json', // Specify that we are sending JSON
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
    body: JSON.stringify(requestBody), // Convert the request body to JSON
  });

  const resp = await response.text();
  // console.log('resp: ' + resp);

  return resp;
};

const addAnnotationToVideo = async (
  videoID: string,
  startFrame: number,
  endFrame: number,
  annotation: string
) => {
  const apiURL = `${process.env.REACT_APP_BACKEND_BASE_URL}/annotations/add`;
  const response = await fetch(apiURL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
    body: JSON.stringify({
      videoID,
      startFrame,
      endFrame,
      description: annotation,
    }),
  });

  return response;
};

const fetchAnnotationsForVideo = async (videoID: string) => {
  try {
    if (!videoID) throw new Error('Missing videoID');

    const token = await auth.currentUser?.getIdToken();
    if (!token) throw new Error('User not authenticated');

    const apiURL = `${process.env.REACT_APP_BACKEND_BASE_URL}/annotations/get?videoID=${videoID}`;

    const response = await fetch(apiURL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error(
        `API request failed: ${response.status} ${response.statusText}`
      );
    }

    const jsonData = await response.json();
    const annotations = jsonData.data?.annotations || [];

    return annotations.sort((a, b) => a.startFrame - b.startFrame);
  } catch (error) {
    console.error('Error fetching annotations:', error);
    return []; // Return empty array to match expected type
  }
};

const deleteAnnotation = async (annotationID: string) => {
  const apiURL =
    `${process.env.REACT_APP_BACKEND_BASE_URL}/annotations/remove?annotationID=` +
    annotationID;
  const response = await fetch(apiURL, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
  });

  return response;
};

const updateAnnotation = async (
  videoID: string,
  ID: string,
  startFrame: number,
  endFrame: number,
  description: string
) => {
  const apiURL = `${process.env.REACT_APP_BACKEND_BASE_URL}/annotations/update`;
  const response = await fetch(apiURL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
    body: JSON.stringify({
      videoID,
      ID,
      startFrame,
      endFrame,
      description,
    }),
  });

  return response;
};

const linkToProject = async (project: string, video: string) => {
  const apiURL = `${process.env.REACT_APP_BACKEND_BASE_URL}/projects/allocate?project=${project}&video=${video}`;
  const response = await fetch(apiURL, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()} `,
    },
  });

  const resp = await response.text();
  // console.log('resp: ' + resp);

  return resp;
};

const unlinkFromProject = async (video: string) => {
  const apiURL = `${process.env.REACT_APP_BACKEND_BASE_URL}/projects/deallocate?video=${video}`;
  const response = await fetch(apiURL, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()} `,
    },
  });

  const resp = await response.text();
  // console.log('resp: ' + resp);

  return resp;
};

/**
 * Gets the current project layout/filter configuration from the server
 * @param projectId The ID of the project
 * @returns A promise that resolves to the project layout data
 */
export const getProjectLayout = async (projectId: string) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/projects/${projectId}/layout`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${await auth.currentUser?.getIdToken()}`,
      },
    });

    if (!response.ok) {
      throw new Error(`Failed to get project layout: ${response.statusText}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error getting project layout:', error);
    throw error;
  }
};

export const updateProjectLayout = async (projectId: string, filters: Filter[]): Promise<boolean> => {
  const apiURL = `${process.env.REACT_APP_BACKEND_BASE_URL}/projects/${projectId}/layout`;
  const response = await fetch(apiURL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
    body: JSON.stringify({ filters }),
  });

  if (!response.ok) {
    const errorText = await response.text();
    throw new Error(`Failed to update project layout: ${errorText}`);
  }

  return true;
};

const deleteProject = async (
  project: string,
  deleteVideos: boolean = false
) => {
  const apiURL =
    `${process.env.REACT_APP_BACKEND_BASE_URL}/projects/delete?project=${project}&deleteVideos=` +
    deleteVideos;
  const response = await fetch(apiURL, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()} `,
    },
  });

  const resp = await response.json();
  // console.log('resp: ' + resp);

  return resp;
};

const updateVideo = async (
  videoID: string,
  videoTitle: string | undefined,
  videoDescription: string | undefined
) => {
  const apiURL = `${process.env.REACT_APP_BACKEND_BASE_URL}/sdk/video/update`;
  const body: { video: string; title?: string; description?: string } = {
    video: videoID,
  };

  if (videoTitle && videoTitle.trim() !== '') {
    body.title = videoTitle;
  }

  if (videoDescription && videoDescription.trim() !== '') {
    body.description = videoDescription;
  }

  const response = await fetch(apiURL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()} `,
    },
    body: JSON.stringify(body),
  });

  const resp = await response.json();
  //console.log('resp: ', resp);

  return resp;
};

const shareProject = async (project: string, email: string) => {
  const apiURL = `${process.env.REACT_APP_BACKEND_BASE_URL}/projects/share?project=${project}&email=${email}`;
  const response = await fetch(apiURL, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()} `,
    },
  });

  const resp = await response.json();
  // console.log('resp: ' + resp);

  return resp;
};

const getJobs = async () => {
  const apiURL = `${process.env.REACT_APP_BACKEND_BASE_URL}/sdk/jobs`;
  const response = await fetch(apiURL, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()} `,
    },
  });

  const { data } = await response.json();

  return data;
};

const getProjectAuthorisedList = async (project: string) => {
  const apiURL = `${process.env.REACT_APP_BACKEND_BASE_URL}/projects/authorised?project=${project}`;
  const response = await fetch(apiURL, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()} `,
    },
  });

  const { data } = await response.json();

  return data;
};

const revokeProjectAccess = async (
  project: string,
  userID: string,
  pending: boolean
) => {
  const apiURL = `${process.env.REACT_APP_BACKEND_BASE_URL}/projects/revoke?project=${project}&revoke=${userID}&pending=${pending}`;
  const response = await fetch(apiURL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()} `,
    },
  });

  const resp = await response.json();

  return resp;
};

const sendInformationForm = async (formInputs: {
  title: string;
  firstName: string;
  surname: string;
  company: string;
  role: string;
  intention: string;
}) => {
  try {
    let formData = new FormData();

    formData.append('title', formInputs.title);
    formData.append('first', formInputs.firstName);
    formData.append('second', formInputs.surname);
    formData.append('company', formInputs.company);
    formData.append('role', formInputs.role);
    formData.append('intention', formInputs.intention);

    await fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/user/update`, {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()} `,
      },
    });
    //console.log('Notification sent after upload');
  } catch (err) {
    console.error('Failed to send information:', err);
  }
};

const updateProject = async (
  project: string,
  title?: string,
  description?: string
) => {
  const apiURL = `${process.env.REACT_APP_BACKEND_BASE_URL}/projects/update`;

  // Prepare the request body with project and description
  const requestBody = {
    id: project,
    project: title,
    description: description,
  };

  const response = await fetch(apiURL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json', // Specify that we are sending JSON
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
    body: JSON.stringify(requestBody), // Convert the request body to JSON
  });

  const resp = await response.json();
  // console.log('resp: ' + resp);

  return resp;
};

export {
  addAnnotationToVideo,
  createProjectInBackend,
  deleteAnnotation,
  deleteProject,
  fetchAnnotationsForVideo,
  getJobs,
  getOverlay,
  getProfile,
  getProjectAuthorisedList,
  getSDKData,
  getThumbnail,
  getUploadLink,
  getUploadProgress,
  getVideo,
  getVideos,
  linkToProject,
  markVideoForDeletion,
  notifyServer,
  permanentlyDeleteVideo,
  revokeProjectAccess,
  sendInformationForm,
  shareProject,
  unlinkFromProject,
  unmarkVideoFromDeletion,
  updateAnnotation,
  updateProject,
  updateVideo,
  uploadVideo,
  addTag,
  getTags,
  updateTag,
  removeTag,
  associateTag,
  updateCategory,
  getCategories,
  addCategory,
  removeCategory,
  sendEmailEnquiry,
};

function convertSchema2(data: any) {
  const converted = {
    emotional_saliency: data['es'],
    graph_stats: data['gs'],
    measurability: data['measurability'],
    interesting_events: data['interesting_events'],
    va_quality: data['vq'],
    va_speed: data['sp'],
    va_stats: data['st'],
    video_health: decodeVideoHealth(data['qu']),
    va: decodeVA(data['va']),
    head_angles: decodeHeadAngles(data['he']),
    schema_version: data['sv']
  };

  return converted;
}

function decodeVideoHealth(encodedQualityString: string) {
  // Decode base64
  const binaryData = atob(encodedQualityString);
  const buffer = new Uint8Array(binaryData.length);

  for (let i = 0; i < binaryData.length; i++) {
    buffer[i] = binaryData.charCodeAt(i);
  }

  const dataView = new DataView(buffer.buffer);
  let offset = 0;

  // Read number of arrays (uint8)
  const numArrays = dataView.getUint8(offset);
  offset += 1;

  // Read lengths (uint32)
  const lengths: number[] = [];
  for (let i = 0; i < numArrays; i++) {
    lengths.push(dataView.getUint32(offset, true)); // true for little-endian
    offset += 4;
  }

  // Read values
  const keys = [
    'face_nondetection',
    'affect_invalid',
    'image_unacceptable',
    'image_blurry',
    'image_dark',
    'image_bright',
    'head_angles_invalid',
  ];

  const result: Record<string, number[]> = {};

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    const length = lengths[i];
    result[key] = [];

    for (let j = 0; j < length; j++) {
      result[key].push(dataView.getUint32(offset, true));
      offset += 4;
    }
  }

  return result;
}

function decodeVA(
  base64String: string | null
): { valence: number | null; arousal: number | null }[] | null {
  if (!base64String) return null;

  try {
    // Decode base64 to binary
    const binaryString = atob(base64String);
    const buffer = new ArrayBuffer(binaryString.length);
    const view = new Uint8Array(buffer);

    for (let i = 0; i < binaryString.length; i++) {
      view[i] = binaryString.charCodeAt(i);
    }

    const dataView = new DataView(buffer);
    const numPairs = buffer.byteLength / 8; // Each pair is 8 bytes (2 float32s)
    const vaPairs: { valence: number | null; arousal: number | null }[] = [];

    for (let i = 0; i < numPairs; i++) {
      const offset = i * 8;
      const valence = dataView.getFloat32(offset, true); // true for little-endian
      const arousal = dataView.getFloat32(offset + 4, true);

      vaPairs.push({
        valence: isFinite(valence) ? valence : null,
        arousal: isFinite(arousal) ? arousal : null,
      });
    }

    return vaPairs;
  } catch (error) {
    console.error('Failed to decode VA data:', error);
    return null;
  }
}

function decodeHeadAngles(
  base64String: string | null
): { pitch: number | null; roll: number | null; yaw: number | null }[] | null {
  if (!base64String) return null;

  try {
    // Decode base64 to binary
    const binaryString = atob(base64String);
    const buffer = new ArrayBuffer(binaryString.length);
    const view = new Uint8Array(buffer);

    for (let i = 0; i < binaryString.length; i++) {
      view[i] = binaryString.charCodeAt(i);
    }

    const dataView = new DataView(buffer);
    const numTriples = buffer.byteLength / 12; // Each triple is 12 bytes (3 float32s)
    const headData: {
      pitch: number | null;
      roll: number | null;
      yaw: number | null;
    }[] = [];

    for (let i = 0; i < numTriples; i++) {
      const offset = i * 12;
      const pitch = dataView.getFloat32(offset, true); // true for little-endian
      const roll = dataView.getFloat32(offset + 4, true);
      const yaw = dataView.getFloat32(offset + 8, true);

      headData.push({
        pitch: isFinite(pitch) ? pitch : null,
        roll: isFinite(roll) ? roll : null,
        yaw: isFinite(yaw) ? yaw : null,
      });
    }

    return headData;
  } catch (error) {
    console.error('Failed to decode head angles:', error);
    return null;
  }
}

const getCategories = async (projectId: string) => {
  const apiURL = `${process.env.REACT_APP_BACKEND_BASE_URL}/projects/${projectId}/categories`;
  const response = await fetch(apiURL, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
  });
  if (!response.ok) return null;

  const resp = await response.json();
  return resp.data.categories;
};

const addCategory = async (projectId: string, categoryData: {
  name: string;
  shared?: boolean
}) => {
  const apiURL = `${process.env.REACT_APP_BACKEND_BASE_URL}/projects/${projectId}/categories/create`;
  const response = await fetch(apiURL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
    body: JSON.stringify(categoryData),
  });
  if (!response.ok) return null;

  const resp = await response.json();
  return resp.data.category;
};

const removeCategory = async (projectId: string, categoryId: string) => {
  const apiURL = `${process.env.REACT_APP_BACKEND_BASE_URL}/projects/${projectId}/categories/remove`;
  const response = await fetch(apiURL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
    body: JSON.stringify({ categoryId }),
  });
  if (!response.ok) return null;

  const resp = await response.json();
  return resp;
};

const getTags = async (projectId: string, categoryId: string, videoId?: string) => {
  let apiURL = `${process.env.REACT_APP_BACKEND_BASE_URL}/projects/${projectId}/categories/${categoryId}/tags`;

  // Add optional video ID query parameter if provided
  if (videoId) {
    apiURL += `?videoId=${videoId}`;
  }

  const response = await fetch(apiURL, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
  });
  if (!response.ok) return null;

  const resp = await response.json();
  return resp.data.tags;
};

const addTag = async (projectId: string, categoryId: string, tagData: {
  name: string;
  videoIds?: string[];
  shared?: boolean
}) => {
  const apiURL = `${process.env.REACT_APP_BACKEND_BASE_URL}/projects/${projectId}/categories/${categoryId}/tags/create`;
  const response = await fetch(apiURL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
    body: JSON.stringify(tagData),
  });
  if (!response.ok) return null;

  const resp = await response.json();
  return resp.data.tag;
};

const removeTag = async (projectId: string, categoryId: string, tagId: string) => {
  const apiURL = `${process.env.REACT_APP_BACKEND_BASE_URL}/projects/${projectId}/categories/${categoryId}/tags/remove`;
  const response = await fetch(apiURL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
    body: JSON.stringify({ tagId }),
  });
  if (!response.ok) return null;

  const resp = await response.json();
  return resp;
};

const associateTag = async (associationData: {
  tagId: string;
  videoIds: string[];
  action: 'add' | 'remove' | 'replace'
}) => {
  const apiURL = `${process.env.REACT_APP_BACKEND_BASE_URL}/tags/associate`;
  const response = await fetch(apiURL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
    body: JSON.stringify(associationData),
  });
  if (!response.ok) return null;

  const resp = await response.json();
  return resp;
};

const updateCategory = async (projectId: string, categoryId: string, categoryData: {
  name?: string;
  shared?: boolean;
}) => {
  const apiURL = `${process.env.REACT_APP_BACKEND_BASE_URL}/projects/${projectId}/categories/update`;
  const response = await fetch(apiURL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
    body: JSON.stringify({
      categoryId,
      ...categoryData
    }),
  });
  if (!response.ok) return null;

  const resp = await response.json();
  return resp.category;
};

const updateTag = async (
  projectId: string,
  categoryId: string,
  tagId: string,
  tagData: {
    name?: string;
    videoIds?: string[];
    shared?: boolean;
  }
) => {
  const apiURL = `${process.env.REACT_APP_BACKEND_BASE_URL}/projects/${projectId}/categories/${categoryId}/tags/update`;
  const response = await fetch(apiURL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`,
    },
    body: JSON.stringify({
      tagId,
      ...tagData
    }),
  });
  if (!response.ok) return null;

  const resp = await response.json();
  return resp.tag;
};
